/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { Navigate, Outlet } from 'react-router-dom';
import { PublicRoute } from '../router';
import DashboardLayout from '../layouts/dashboard/DashboardLayout';
import { useAuthStore } from '../hooks';
import { LoadingPage } from '../pages';

const AuthGuard = () => {
    const { status, checkAuthToken } = useAuthStore();
    useEffect(() => {
        checkAuthToken();
    }, [])

    if (status === 'checking') {
        return ( 
            <LoadingPage />
        )
    }
    
    if (status === 'authenticated' || localStorage.getItem('token') !== null) {
        return ( 
            <DashboardLayout>
                <Outlet />
            </DashboardLayout>
        )
    }

    return <Navigate replace to={PublicRoute.LOGIN} />;
}

export default AuthGuard;
