import { useState } from 'react';
// @mui
import { Card, Stack, Grid, Button, Container, Typography, Skeleton, } from '@mui/material';
// components
import Iconify from '../../components/iconify';
import Modal from '../../components/modal';
import { UserForm } from '../../components/forms';
import TableCustomer from '../../components/table/TableCustomer';
// hooks
import { useUsers } from '../../hooks';

const TABLE_HEAD = [
  { id: 'name', label: 'Nombre', alignRight: false },
  { id: 'email', label: 'Correo Electronico', alignRight: false },
  { id: 'role', label: 'Grupo', alignRight: false },
  { id: 'isVerified', label: 'Verificado', alignRight: false },
  { id: 'status', label: 'Estado', alignRight: false },
  { id: '' },
];

export default function UserPage() {
  const { users, loadingInfo, usersData } = useUsers();
  const [open, setOpen] = useState(false);
  const [usr, setUsr] = useState(null);

  const handleCloseModal = () => {
    setOpen(false);
    setUsr(null);
  };

  const onSubmit = () => {
    setUsr(null);
    setOpen(false);
    usersData();
  }

  return (
    <Container maxWidth="xl">
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Usuarios
        </Typography>
        {
          loadingInfo ? 
          <Stack spacing={1}>
            <Skeleton variant="rounded" width={153} height={36} />
          </Stack>
          : 
          <Button variant="contained" onClick={() => setOpen(true)} startIcon={<Iconify icon="eva:plus-fill" />}>
            Nuevo Usuario
          </Button>
        }
      </Stack>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            {
              loadingInfo ? 
              <Stack spacing={1}>
                <Skeleton variant="rounded" width={1200} height={350} />
              </Stack>
              : 
              <TableCustomer tableHead={TABLE_HEAD} typeTable="users" refresh={onSubmit} data={users} />
            }
          </Card>
        </Grid>
      </Grid>
      <Modal title="Nuevo Usuario" maxWidth={'xs'} open={open} onClose={handleCloseModal}>
        <UserForm usr={usr} onSubmit={onSubmit}/>
      </Modal>
    </Container>
  );
}
