/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import { 
  SvgIcon, 
  Card,
   Container, 
   Typography,
    Grid, 
    Stack, 
    Skeleton, 
    Button, 
    CircularProgress,
    FormHelperText,
    MenuItem,
    Select,
    CardContent
  } from '@mui/material';
import Download01 from '@untitled-ui/icons-react/build/esm/Download01';
import { exportToExcel } from 'react-json-to-excel';
import { useApprover, useAuthStore, useBusinessUser, usePurchases } from '../../hooks';
import TableCustomer from '../../components/table';

const TABLE_HEAD = [
  { id: 'DocNum', label: 'No.Documento', alignRight: false },
  { id: 'DocDate', label: 'Fecha', alignRight: false },
  { id: 'Comments', label: 'Comentario', alignRight: false },
  { id: 'DocumentStatus', label: 'Estado', alignRight: false },
  { id: 'AuthorizationStatus', label: 'Authorizado', alignRight: false },
  { id: '' },
];

const TABLE_HEAD2 = [
  { id: 'id', label: '#', alignRight: false },
  { id: 'uuid', label: 'Folio', alignRight: false },
  { id: 'createdAt', label: 'Fecha', alignRight: false },
  { id: 'comments', label: 'Comentario', alignRight: false },
  { id: 'status', label: 'Estado', alignRight: false },
  { id: '' },
];

export default function HistoryPage() {
  const { data } = useAuthStore();
  const { requestsApprovalUsrs } = useApprover();
  const { docPurchases, excelBlob, setExcelBlob, GetPurchaseRequest, ReportDocument } = usePurchases();
  const { businessRelation, FindRelationBusinessUser } = useBusinessUser();
  const [ change, setChange ] = useState(true);
  const [ documentsDrafts, setDocumentDrafts ] = useState([]);
  const [ downloadXlsx, setDownloadXlsx ] = useState(false);
  const [ activeButton, setActiveButton ] = useState('purchases');
  const [ loading, setLoading ] = useState(true);
  const [ isActive, setIsActive ] = useState(false);
  const [ selectInput, setSelectInput ] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      await FindRelationBusinessUser(data?.user?.id);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await GetPurchaseRequest();
      setLoading(false);
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (excelBlob) {
      exportToExcel(excelBlob, `Reporte ${Date.now()}`);
      setDownloadXlsx(false);
      setExcelBlob(null);
    }
  }, [excelBlob]);

  const handleFilter = async (event) => {
    localStorage.setItem('business', event.target.value);
    setSelectInput(event.target.value);
    setLoading(true);
    setIsActive(true);
    if (activeButton === 'purchases') {
      setChange(true);
      setActiveButton('purchases');
      await GetPurchaseRequest();
      setLoading(false);
    } else {
      setChange(false);
      setActiveButton('drafts');
      const { success, result } = await requestsApprovalUsrs();
      setDocumentDrafts(result);
      setLoading(false);
    }
  }

  const changeDocuments = async (type) => {
    setLoading(true);
    if (type === 'purchases') {
      setChange(true);
      setActiveButton('purchases');
      await GetPurchaseRequest();
      setLoading(false);
    } else {
      setChange(false);
      setActiveButton('drafts');
      const { success, result } = await requestsApprovalUsrs();
      setDocumentDrafts(result);
      setLoading(false);
    }
  }

  const handleDownload = async () => {
    setDownloadXlsx(true);
    await ReportDocument();
  }

  return (
    <Container maxWidth="xl">
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
        <Stack alignItems="center" direction="row" spacing={2}>
          <div>
            <Typography variant="h4" >
              Histórico
            </Typography>
            <Typography color="text.secondary" variant="h6">
              Solicitudes de compras { activeButton === 'purchases' ? 'aprobados':'pendientes'}
            </Typography>
          </div>
        </Stack>
      </Stack>

      <Card>
        <CardContent>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
            <Stack alignItems="center" direction="row" spacing={2}>
              <Select 
                autoFocus
                id="Business"
                name="Business"
                size="small"
                variant="outlined"
                onChange={handleFilter}
                value={selectInput}
                fullWidth
              >
                <MenuItem value={0}>Selecciona una sociedad</MenuItem>
                {businessRelation.map(
                  (item, index) => (
                    <MenuItem key={index} value={item.business.id}>({item.code}) - {item.business.businessName}</MenuItem>
                  )
                )}
              </Select>
            </Stack>
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
            <Stack alignItems="center" direction="row" spacing={1}>
              <Button disabled={!isActive} size="small" variant="outlined" 
                onClick={() => handleDownload()}>
                {
                  downloadXlsx ?
                    <CircularProgress size={20} style={{ marginRight: '5px' }}/>
                  :
                  <>
                    <SvgIcon style={{marginRight: '5px'}}>
                      <Download01 />
                    </SvgIcon>
                    Descargar
                  </>
                }
              </Button>
            </Stack>
            <Stack alignItems="center" direction="row" spacing={1}>
              <Button size="small" variant="outlined" 
                disabled={activeButton === 'purchases'}
                onClick={() => changeDocuments('purchases')}>
                Aprobados
              </Button>
              <Button size="small" variant="outlined" 
                disabled={activeButton === 'drafts'}
                onClick={() => changeDocuments('drafts')}>
                Pendientes
              </Button>
            </Stack>
          </Stack>
        </CardContent>
      </Card>

      {
        selectInput !== 0 ?
        <Card spacing={3} style={{marginTop: 10}}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card>
                {
                  loading ? 
                  <Stack spacing={1}>
                    <Skeleton variant="rounded" height={350} />
                  </Stack>
                  :
                  <>
                    {
                      change ? 
                      <TableCustomer tableHead={TABLE_HEAD} typeTable="purchaseRequest" data={docPurchases} />
                      :
                      <TableCustomer tableHead={TABLE_HEAD2} typeTable="drafts" data={documentsDrafts} />
                    }
                  </>
                }
              </Card>
            </Grid>
          </Grid>
        </Card>:null
      }
    </Container>
  )
}
