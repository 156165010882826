/* eslint-disable import/order */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useState } from 'react'
// @mui
import { Stack, Popover, TableRow, MenuItem, TableCell, Typography, IconButton } from '@mui/material';

import Iconify from '../../iconify';
import Label from '../../label';
import Modal from '../../modal';

import moment from 'moment';
import { RulesForm } from '../../forms';
import { useRules } from '../../../hooks';

export default function BodyRules(props){
  const { filtered, page, rowsPerPage, emptyRows, refresh } = props;
  const { deRules } = useRules();

  const [rules, setRules] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleOpenMenu = (event, business) => {
    setRules(business);
    setOpenMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenu(null);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  }

  const handleCloseModal = () => {
    setRules(null);
    setOpenModal(false);
    setOpenMenu(false);
  };

  const onSubmit = () => {
    setRules(null);
    setOpenModal(false);
    setOpenMenu(false);
    refresh();
  };

  const handleDestroy = async (id) => {
    const { result } = await deRules(id);
    handleCloseMenu();
    handleCloseModal();
    refresh();
  };

  return (
    <>
      {
        filtered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
          const { id, description, ends_in, go_with, status, created_at } = row;
          const formattedDate = moment(created_at).format("DD/MM/YYYY");
          return (
            <TableRow hover key={id} tabIndex={-1}>
              <TableCell component="th" scope="row">
                <Stack direction="row" alignItems="center" spacing={2}>
                  {/* <Avatar alt={name} src={avatarUrl} /> */}
                  <Typography variant="subtitle2" noWrap>
                    {description}
                  </Typography>
                </Stack>
              </TableCell>
              <TableCell align="left">
                {ends_in}
              </TableCell>
              <TableCell align="left">
                {go_with}
              </TableCell>
              <TableCell align="left"
                onClick={() => handleDestroy(id)}
                style={{
                    cursor: 'pointer'
                }}
              >
                <Label 
                    color={(status ? 'success':'error')}
                    style={{
                        cursor: 'pointer'
                    }}
                >
                    {(status ? 'Activo':'Inactivo')}
                </Label>
              </TableCell>
              <TableCell align="left">
                <Label color={'info'}>
                  {formattedDate}
                </Label>
              </TableCell>
              <TableCell align="right">
                <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, row)}>
                <Iconify icon={'eva:more-vertical-fill'} />
                </IconButton>
              </TableCell>
            </TableRow>
          );
        })
      }

      {
        emptyRows > 0 && (
          <TableRow style={{ height: 53 * emptyRows }}>
            <TableCell colSpan={7} />
          </TableRow>
        )
      }

      <Popover
        open={Boolean(openMenu)} 
        anchorEl={openMenu} 
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75
            }
          }
        }}
      >
      <MenuItem onClick={handleOpenModal}>
        <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
        Editar
      </MenuItem>
    </Popover>

    <Modal title="Modificar Empresa" maxWidth={'xs'} open={openModal} onClose={handleCloseModal}>
      <RulesForm rules={rules} reload={onSubmit}/>
    </Modal>
  </>
  )
}

