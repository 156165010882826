/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import {
    styled,
    TextField,
    Button,
    Paper,
    Grid,
    CircularProgress,
    IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import * as yup from 'yup';
import {RegisterSchema} from '../../../utils/validacionSchema';
import { useUsers } from '../../../hooks';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function ProfileForm(props){
  const { usr } = props;
  const { loading, UpdateProfile } = useUsers();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [formData, setFormData] = useState({
    name: usr != null ? usr.name:'', 
    lastNameP: usr != null ? usr.lastNameP:'', 
    lastNameM: usr != null ? usr.lastNameM:'', 
    email: usr != null ? usr.email:'',
    rfc: usr != null ? usr.rfc:'',
  });
  const [errors, setErrors] = useState([]);

  const inputChange = (event) => {
      const { name, value } = event.target;
      let processedValue = value;

      if (name === 'name') {
        processedValue = value.replace(/\b[a-z]/g, (letter) => letter.toUpperCase());
      } else if (name === 'lastNameP') {
        processedValue = value.replace(/\b[a-z]/g, (letter) => letter.toUpperCase());
      } else if (name === 'lastNameM') {
        processedValue = value.replace(/\b[a-z]/g, (letter) => letter.toUpperCase());
      } else if (name === 'email') {
        processedValue = value;
      } else if (name === 'rfc') {
        processedValue = value.toUpperCase();
      }
      setFormData((prevState) => ({
        ...prevState,
        [name]: processedValue,
      }));
      RegisterSchema.validateAt(name, { [name]: processedValue })
      .then(() => {
        setErrors({ ...errors, [name]: "" });
      })
      .catch((err) => {
        setErrors({ ...errors, [name]: err.errors[0] });
      });
  };

  const submitForm = async () => {
    try {
      await RegisterSchema.validate(formData, { abortEarly: false });
      await UpdateProfile(usr.id, formData);
      setErrors({});
    } catch (err) {
      enqueueSnackbar(`Formulario incompleto!`, {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
        action: (key) => (
          <IconButton onClick={() => { closeSnackbar(key) }}>
            <CloseIcon />
          </IconButton>
        )
      });
      if (err instanceof yup.ValidationError) {
        setErrors(err.inner.reduce((acc, curr) => {
          acc[curr.path] = curr.message;
          return acc;
        }, {}));
      }
    }
  }

  return (
    <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={12}>
        <Item>
          <TextField 
            id="name"
            name="name"
            label="Nombre(s)" 
            type="text" 
            size="small" 
            variant="outlined" 
            fullWidth 
            onChange={inputChange}
            value={formData.name}
            error={Boolean(errors.name)}
            helperText={errors.name}
            autoComplete="off" />
        </Item>
      </Grid>
      <Grid item xs={12}>
        <Item>
          <TextField 
            id="lastNameP" 
            name="lastNameP"
            label="Apellido Paterno"
            type="text"
            size="small" 
            variant="outlined" 
            fullWidth 
            onChange={inputChange}
            value={formData.lastNameP}
            error={Boolean(errors.lastNameP)}
            helperText={errors.lastNameP}
            autoComplete="off" />
        </Item>
      </Grid>
      <Grid item xs={12}>
        <Item>
          <TextField 
            id="lastNameM" 
            name="lastNameM"
            label="Apellido Materno"
            type="text"
            size="small"
            variant="outlined"
            fullWidth
            onChange={inputChange}
            value={formData.lastNameM}
            error={Boolean(errors.lastNameM)}
            helperText={errors.lastNameM}
            autoComplete="off" />
        </Item>
      </Grid>
      <Grid item xs={12}>
        <Item>
          <TextField 
            id="rfc" 
            name="rfc"
            label="RFC (opcional)"
            type="text"
            size="small"
            variant="outlined"
            fullWidth
            onChange={inputChange}
            value={formData.rfc}
            autoComplete="off" />
        </Item>
      </Grid>
      <Grid item xs={12}>
        <Item>
          <TextField 
            id="email"
            name="email"
            size="small"
            label="Correo electrónico"
            type="text"
            variant="outlined"
            onChange={inputChange}
            value={formData.email}
            fullWidth
            error={Boolean(errors.email)}
            helperText={errors.email}
            autoComplete="off" />
        </Item>
      </Grid>
      <Grid item xs={12}>
        <Item>
          <Button variant="contained" onClick={submitForm} disabled={loading} style={{float: "right"}} >
            Actualizar
            {
              loading ? (
                <CircularProgress size={20} color="inherit" style={{ marginLeft: '10px' }}/>
              ):(
                ''
              )
            }
          </Button>                   
        </Item>
      </Grid>
    </Grid>
  )
}
