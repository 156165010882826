import { useEffect, useState } from 'react';
// @mui
import { Card, Stack, Grid, Button, Container, Typography, Skeleton, } from '@mui/material';
// components
import Iconify from '../../components/iconify';
import Modal from '../../components/modal';
import { UserForm } from '../../components/forms';
import TableCustomer from '../../components/table/TableCustomer';
// hooks
import { useRequestApproval, useUsers } from '../../hooks';

const TABLE_HEAD = [
  { id: 'id', label: '#', alignRight: false },
  { id: 'sociedad', label: 'Sociedad', alignRight: false },
  { id: 'solicitante', label: 'Solicitante', alignRight: false },
  { id: 'status', label: 'Estado', alignRight: false },
  { id: 'createdAt', label: 'Registro', alignRight: false },
  { id: '' },
];

export default function ApprovalPage() {
  const { loadingInfo } = useUsers();
  const { reqApprFindAll } = useRequestApproval();
  const [ requests, setRequests ] = useState([]);
  const [open, setOpen] = useState(false);
  const [usr, setUsr] = useState(null);

  const fetchReqApprovals = async () => {
    const { success, result } = await reqApprFindAll();
    setRequests(result);
  }

  useEffect(() => {
    fetchReqApprovals();
  }, [])
  

  return (
    <Container maxWidth="xl">
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Solicitudes de compra
        </Typography>
      </Stack>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            {
              loadingInfo ? 
              <Stack spacing={1}>
                <Skeleton variant="rounded" width={1200} height={350} />
              </Stack>
              : 
              <TableCustomer tableHead={TABLE_HEAD} typeTable="requestPending" data={requests} />
            }
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
