/* eslint-disable react/prop-types */
/* eslint-disable consistent-return */
import React from 'react'
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { useAuthStore } from '../hooks';
import { LoadingPage, Page404 } from '../pages';

function AuthRole(props) {
    const { roles } = props;
    const { data } = useSelector(state => state.auth);
    const { status } = useAuthStore();
    
    if (status === 'checking') {
        return ( <LoadingPage /> )
    }
    
    if (roles.includes(data?.user?.role)) {
        return ( <Outlet /> );
    }
    
    return ( 
        <Page404 />
    )
}

export default AuthRole;
