/* eslint-disable react/prop-types */
import React, { useState } from 'react'
// @mui
import { Stack, Popover, TableRow, Avatar, MenuItem, TableCell, Typography, IconButton } from '@mui/material';

import { UserForm, BusinessUserForm, CenterCostUserForm, ApproverUserForm } from '../../forms';
import Iconify from '../../iconify';
import Label from '../../label';
import Modal from '../../modal';
import { useUsers } from '../../../hooks';
import { renderRole } from '../../../utils';

export default function BodyUser(props){
  const { filtered, page, rowsPerPage, emptyRows, refresh, openBackdrop } = props;
  const { ChangeStatus, ResetPassword } = useUsers();

  const [data, setData] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalType, setOpenModalType] = useState('user');

  const handleOpenMenu = (event, usr) => {
    setData(usr);
    setOpenMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenu(null);
  };

  const handleOpenModal = (type) => {
    setOpenModal(true);
    setOpenModalType(type);
  }

  const handleCloseModal = () => {
    setData(null);
    setOpenModal(false);
    setOpenMenu(false);
  };

  const handleResetPassword = async () => {
    setOpenMenu(false);
    openBackdrop(true);
    try {
      await ResetPassword(data?.id);
      openBackdrop(false);
    } catch (err) {
      console.log(err);
    }
  }

  const changeStatus = async (row) => {
    await ChangeStatus(row.id);
    refresh();
}

  const onSubmit = () => {
    setData(null);
    setOpenModal(false);
    setOpenMenu(false);
    refresh();
  }

  return (
    <>
      {
        filtered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
          const { id, name, lastNameP, email, role, isVerified, status, approval } = row;
          return (
            <TableRow hover key={id} tabIndex={-1}>
              <TableCell component="th" scope="row">
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Avatar alt={name}>
                    {name.substring(0, 1)}
                  </Avatar>
                  <Typography variant="subtitle2" noWrap>
                    {name} {lastNameP}
                  </Typography>
                </Stack>
              </TableCell>
              <TableCell align="left">
                {email}
              </TableCell>
              <TableCell align="left">
                <Label color={'info'}>
                  {approval ? "Aprobador":renderRole(role)}
                </Label>
              </TableCell>
              <TableCell align="left">
                <Label color={(isVerified ? 'success':'error')}>{(isVerified ? 'Sí':'No')}</Label>
              </TableCell>
              <TableCell align="left">
                <Label 
                  style={{cursor: 'pointer'}} 
                  onClick={() => changeStatus(row)} 
                  color={(status ? 'success':'error')}
                >
                  {(status ? 'Activo':'Inactivo')}
                </Label>
              </TableCell>
              <TableCell align="right">
                <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, row)}>
                <Iconify icon={'eva:more-vertical-fill'} />
                </IconButton>
              </TableCell>
            </TableRow>
          );
        })
      }

      {
        emptyRows > 0 && (
          <TableRow style={{ height: 53 * emptyRows }}>
            <TableCell colSpan={7} />
          </TableRow>
        )
      }

      <Popover
        open={Boolean(openMenu)} 
        anchorEl={openMenu} 
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75
            }
          }
        }}
      >
        <MenuItem onClick={() => handleOpenModal('user')}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Editar
        </MenuItem>

        <MenuItem onClick={() => handleOpenModal('approvals')}>
          <Iconify icon={'eva:shield-outline'} sx={{ mr: 2 }} />
          Aprobador
        </MenuItem>

        <MenuItem onClick={() => handleOpenModal('centercost')}>
          <Iconify icon={'mdi:set-center'} sx={{ mr: 2 }} />
          C.Costos
        </MenuItem>

        <MenuItem onClick={() => handleOpenModal('business')}>
          <Iconify icon={'ic:round-business'} sx={{ mr: 2 }} />
          Empresas
        </MenuItem>

        <MenuItem onClick={handleResetPassword}>
          <Iconify icon={'mdi:email-fast'} sx={{ mr: 2 }} />
          Restablacer
        </MenuItem>
      </Popover>

      {openModalType === 'user' && (
        <Modal title="Modificar Usuario" maxWidth={'xs'} open={openModal} onClose={handleCloseModal}>
          <UserForm usr={data} onSubmit={onSubmit}/>
        </Modal>
      )}
      {openModalType === 'approvals' && (
        <Modal title="Agregar relación aprobador" maxWidth={'xs'} open={openModal} onClose={handleCloseModal}>
          <ApproverUserForm data={data} onSubmit={onSubmit}/>
        </Modal>
      )}
      {openModalType === 'business' && (
        <Modal title="Modificar Empresas" maxWidth={'xs'} open={openModal} onClose={handleCloseModal}>
          <BusinessUserForm data={data} onSubmit={onSubmit} />
        </Modal>
      )}
      {openModalType === 'centercost' && (
        <Modal title="Modificar Centro de costos" maxWidth={'xs'} open={openModal} onClose={handleCloseModal}>
          <CenterCostUserForm data={data} onSubmit={onSubmit} />
        </Modal>
      )}
    </>
  )
}
