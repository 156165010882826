import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { KeyboardArrowDownRounded, KeyboardArrowLeftRounded } from '@mui/icons-material';
import { NavLink as RouterLink } from 'react-router-dom';

// @mui
import { Box, List, ListItemText, Collapse } from '@mui/material';
import { StyledNavItem, StyledNavItemIcon } from './styles';

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
  const [open, setOpen] = useState(null);

  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item) => (
          <NavItem key={item.title} item={item} open={open} setOpen={setOpen} />
        ))}
      </List>
    </Box>
  );
}

NavItem.propTypes = {
  item: PropTypes.object,
  open: PropTypes.number,
  setOpen: PropTypes.func,
};

function NavItem({ item, open, setOpen }) {
  const { title, path, icon, info, role, subitems } = item;
  const { data } = useSelector(state => state.auth);

  const handleClick = () => {
    if (!subitems) {
      setOpen(null);
    } else if (open === item.index) {
      setOpen(null);
    } else {
      setOpen(item.index);
    }
  };

  return (
    role.includes(data?.user?.role) ? (
      <>
        <StyledNavItem 
          component={subitems ? undefined : RouterLink}
          to={subitems ? undefined : path}
          onClick={handleClick}
          sx={{'&.active': { color: 'text.primary', bgcolor: 'action.selected', fontWeight: 'fontWeightBold'}}}
        >
          <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
          <ListItemText disableTypography primary={title} />
          {info && info}
          {subitems && (open === item.index ? <KeyboardArrowDownRounded /> : <KeyboardArrowLeftRounded />)}
        </StyledNavItem>
        {subitems && open === item.index && (
          <Collapse in={open === item.index} timeout="auto" unmountOnExit>
            <NavSection data={subitems} />
          </Collapse>
        )}
      </>
    ) : (
      null
    )
  );
}
