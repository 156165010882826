/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/prop-types */
import React, {useState, useEffect} from 'react'
import { useSnackbar } from 'notistack';
import {
    styled,
    TextField,
    Button,
    Paper,
    Grid,
    Chip,
    MenuItem,
    Select,
    CircularProgress,
    IconButton,
    Skeleton,
    Stack,
    FormHelperText 
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import * as yup from 'yup';
import { useBusiness, useBusinessUser } from '../../../hooks';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function BusinessUserForm(props){
    const { data } = props;
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { business } = useBusiness();
    const { businessRelation, loading, skeleting, RelationBusinessUser, FindRelationBusinessUser, DeleteRelationBusinessUser } = useBusinessUser();
    const [ selectedIndex, setSelectedIndex ] = useState(-1);
    const [ formData, setFormData ] = useState({user: data?.id, codeEmploye: '', business: 0});
    const [ btn, setBtn ] = useState('Añadir');
    const [ errors, setErrors ] = useState([]);
    
    useEffect(() => {
        FindRelationBusinessUser(data?.id);
    }, []);
    const BusinessUserSchema = yup.object().shape({
        codeEmploye: yup
            .string()
            .min(1, 'Mínimo 1 carácteres')
            .required('No.Empleado es requerida'),
        business: yup
            .number()
            .oneOf(business.map(item => item.id), 'Selecciona una empresa')
            .required('La empresa es requerida'),
    });

    const inputChange = (event) => {
        const { name, value } = event.target;
        let processedValue = value;
        if (name === 'codeEmploye') {
            processedValue = value;
        } else if (name === 'business') {
            processedValue = value;
        }
        setFormData((prevState) => ({
            ...prevState,
            [name]: processedValue,
        }));
        BusinessUserSchema.validateAt(name, { [name]: processedValue })
        .then(() => {
            setErrors({ ...errors, [name]: "" });
        })
        .catch((err) => {
            setErrors({ ...errors, [name]: err.errors[0] });
        });
    };
    
    const addEmployesInUser = async () => {
        let exit = false;
        setBtn('Añadiendo');
        
        businessRelation.forEach(employe => {
            if (employe.codeEmploye === formData.codeEmploye && employe.business_id === formData.business){
                exit = true;
            }
        });

        if (exit) {
            setBtn('Añadir');
            enqueueSnackbar(`No.Empleado, ya ha sido relacionado!`,{
                variant: 'error',
                autoHideDuration: 3000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
                action: (key) => (
                    <IconButton onClick={() => { closeSnackbar(key) }}>
                      <CloseIcon />
                    </IconButton>
                )
            });
            return;
        }

        try {
            await BusinessUserSchema.validate(formData, { abortEarly: false });
            if (!formData.business) {
                setBtn('Añadir');
                return;
            }
            await RelationBusinessUser(formData);
            FindRelationBusinessUser(data.id);
            setBtn('Añadir');
        } catch (err) {
            enqueueSnackbar(`Formulario incompleto!`, {
                variant: 'error',
                autoHideDuration: 3000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
                action: (key) => (
                    <IconButton onClick={() => { closeSnackbar(key) }}>
                      <CloseIcon />
                    </IconButton>
                )
            });
            if (err instanceof yup.ValidationError) {
                setErrors(err.inner.reduce((acc, curr) => {
                    acc[curr.path] = curr.message;
                    return acc;
                }, {}));
            }
            setBtn('Añadir');
        }
    };

    const employesDelete = async (id, index) => {
        setSelectedIndex(index);
        await DeleteRelationBusinessUser(id);
        await FindRelationBusinessUser(data.id);
        setSelectedIndex(-1);
    };

    return (
        <Grid container>
            <Grid item xs={12} sm={12} md={12}>
                <Item>
                    <TextField 
                        autoFocus 
                        id="codeEmploye"
                        name="codeEmploye"
                        label="No.Empleado" 
                        type="text"
                        size="small" 
                        variant="outlined"
                        onChange={inputChange}
                        value={formData.codeEmploye}
                        error={Boolean(errors.codeEmploye)}
                        helperText={errors.codeEmploye}
                        fullWidth 
                        autoComplete="off" />
                </Item>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                <Item>
                    <Select 
                        autoFocus
                        id="business"
                        name="business"
                        size="small"
                        variant="outlined"
                        onChange={inputChange}
                        value={formData.business}
                        error={Boolean(errors.business)}
                        fullWidth
                    >
                        <MenuItem value={0}>Selecciona una empresa</MenuItem>
                        {
                            business.map(
                                (item, index) => (
                                    <MenuItem key={index} value={item.id}>{item.businessName}</MenuItem>
                                )
                            )
                        }
                    </Select>
                    <FormHelperText error>{errors.business}</FormHelperText>
                </Item>
            </Grid>
            <Grid item xs={12} style={{display:'flex', justifyContent:'flex-end'}}>
                <Item>
                    <Button variant="contained" onClick={addEmployesInUser}>
                        {btn}
                        {
                            loading ? (
                                <CircularProgress size={20} color="inherit" style={{ marginLeft: '10px' }}/>
                            ):(
                                ''
                            )
                        }
                    </Button>   
                </Item>
            </Grid>
            <Grid item xs={12}>
                {
                    skeleting ? 
                        <Stack spacing={1} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <Skeleton variant="rounded" width={200} height={40} />
                        </Stack>
                    :
                    <>
                        {
                            businessRelation.map(
                                (item, index) => (
                                    <Grid item xs={12} key={index} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                        {selectedIndex === index ? (
                                            <Chip 
                                                variant="outlined" 
                                                label={`${item.codeEmploye} - ${item.business.businessName}`}
                                                color="error"
                                                avatar={selectedIndex ? <CircularProgress size={20} color="inherit" style={{ marginLeft: '10px' }}/> : null} 
                                            />
                                        ) : (
                                            <Chip 
                                                variant="outlined" 
                                                style={{margin: '5px'}}
                                                label={`#${item.code} - ${item.business.businessName}`} 
                                                onDelete={() => employesDelete(item.id, index)} 
                                            />
                                        )}
                                    </Grid>
                                )
                            )
                        }
                    </>
                }
            </Grid>
        </Grid>
    )
}
