import React, {useEffect} from 'react'
import { usePurchases } from '../../../hooks';

export default function SolpedDetail(props){
    const { docEntry } = props;
    const { docSolped,SolpedDocument } = usePurchases();

    useEffect(() => {
        const fetchData = async () => {
            await SolpedDocument(docEntry);
        };
        fetchData();
    }, []);
    
  return (
    <div>SolpedDetail {docEntry}</div>
  )
}
