/* eslint-disable consistent-return */
import { useState } from 'react';
import { useSelector } from 'react-redux'; 
import { Login, GetItemsQuery, GetTasxesQuery, GetItemsFindQuery, GetBussinessPartnersQuery, GetWarehousesQuery, GetCashFlowQuery, GetCenterCostQuery } from '../../service';

export const useAuthSap = () => {
    const { user } = useSelector(state => state.user);
    const [collectionQuery, setCollectionQuery] = useState([]);
    const [collectionWH, setCollectionWH] = useState([]);
    const [collectionCF, setCollectionCF] = useState([]);
    const [collectionCC, setCollectionCC] = useState([]);

    const LoginSap = async (body) => {
        try {
            const { data } = await Login(body);
        } catch (error) {
            console.log(error);
        }
    }
    
    const ItemsQuery = async () => {
        try {
            const { data } = await GetItemsQuery(`?business=${localStorage.getItem('business')}`);
            setCollectionQuery(data.result);
        } catch (error) {
            console.log(error);
        }
    }

    const TaxesQuery = async (businessid = null) => {
        try {
            const { data:{ success, message, result } } = await GetTasxesQuery(`?business=${businessid || localStorage.getItem('business')}`);
            return { success, message, result };
        } catch (error) {
            console.log(error);
        }
    }

    const ItemsFindQuery = async (itemcode) => {
        try {
            const { data:{ success, message, result } } = await GetItemsFindQuery(itemcode, `?business=${localStorage.getItem('business')}`);
            return result;
        } catch (error) {
            console.log(error);
        }
    }

    const BussinessPartnersQuery = async () => {
        try {
            const { data } = await GetBussinessPartnersQuery(`?business=${localStorage.getItem('business')}`);
            setCollectionQuery(data.result);
        } catch (error) {
            console.log(error);
        }
    }

    const Warehouses = async () => {
        try {
            const { data } = await GetWarehousesQuery(`?business=${localStorage.getItem('business')}`);
            setCollectionWH(data.result);
        } catch (error) {
            console.log(error);
        }
    }

    const CashFlow = async () => {
        try {
            const { data } = await GetCashFlowQuery(`?business=${localStorage.getItem('business')}`);
            setCollectionCF(data.result);
        } catch (error) {
            console.log(error);
        }
    }

    const CenterCost = async (id = user?.id) => {
        try {
            console.log(id);
            const { data:{ success, message, result }  } = await GetCenterCostQuery(id, `?business=${localStorage.getItem('business')}`);
            setCollectionCC(result);
            return { success, result };
        } catch (error) {
            console.log(error);
        }
    }

    const CenterCostBusiness = async ( businessid) => {
        try {
            const id = user?.id;
            const { data:{ success, message, result }  } = await GetCenterCostQuery(id, `?business=${businessid}`);
            setCollectionCC(result);
            return { success, result };
        } catch (error) {
            console.log(error);
        }
    }

    return {
        // Propiedades
        collectionQuery,
        collectionWH,
        collectionCF,
        collectionCC,
        // Metodos
        LoginSap,
        ItemsQuery,
        TaxesQuery,
        BussinessPartnersQuery,
        Warehouses,
        CashFlow,
        CenterCost,
        CenterCostBusiness,
        ItemsFindQuery
    }
}