import SvgColor from '../components/svg-color';

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'Inicio',
    path: '/inicio',
    icon: icon('ic_apps'),
    role: ['develop','admin','consult','client','user','approval'],
  },
  {
    title: 'Usuarios',
    path: '/usuarios',
    icon: icon('ic_users'),
    role: ['develop','admin','consult','client'],
  },
  {
    title: 'Empresas',
    path: '/empresas',
    icon: icon('ic_business'),
    role: ['develop','admin','consult', 'client'],
  },
  {
    title: 'Centro Costos',
    path: '/centro-costo',
    icon: icon('ic_centercost'),
    role: ['develop','admin','consult','client'],
  },
  {
    title: 'Reglas',
    path: '/reglas-validaciones',
    icon: icon('ic_centercost'),
    role: ['develop','admin','consult','client'],
  },
  {
    title: 'Autorizaciones',
    path: '/solicitudes-por-autorizar',
    icon: icon('ic_purchase'),
    role: ['develop','admin','consult','client','approval'],
  },
  {
    title: 'Solicitud Compra',
    path: '/solicitud-compra',
    icon: icon('ic_purchase'),
    role: ['develop','admin','consult','client', 'user'],
  },
  {
    title: 'Histórico',
    path: '/historico',
    icon: icon('ic_list'),
    role: ['develop','admin','consult','client', 'user'],
  },
  {
    title: 'Documentos',
    path: '/documentos',
    icon: icon('ic_list'),
    role: ['develop','admin','consult','client'],
  },
  {
    title: 'Logs',
    path: '/logs',
    icon: icon('ic_log'),
    role: ['develop'],
  }
];

export default navConfig;
