import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'; 
import { useSnackbar } from 'notistack';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { GetUsers, GetUser, PostUser, PutUser, GetResetPass, DeleteUser } from '../../service';
import { onUser } from '../../store/user';

export const useUsers = () =>{
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { user } = useSelector(state => state.user);
  const [ users, setUsers ] = useState([]);
  const [ loading, setLoading ] = useState(false);
  const [ loadingInfo, setLoadingInfo ] = useState(false);
  const [ sendResponse, setSendResponse ] = useState(false);

  async function usersData(){
    try {
      const { data } = await GetUsers();
      setLoading(false);
      setLoadingInfo(false);
      setUsers(data.result);
    } catch (err) {
      setLoading(true);
    }
  }

  useEffect(() => {
    usersData();
  }, []);

  const FindUser = async (id) => {
    try {
      const { data } = await GetUser(id);
      setLoading(false);
      dispatch(onUser(data.result));
    } catch (error) {
      setLoading(false);
    }
  }

  const createUser = async (body) => {
    setLoading(true);
    await PostUser(body).then(
      (result) => {
        const { data } = result;
        setLoading(false);
        setSendResponse(true);
        enqueueSnackbar(`${data.message}`, {
          variant: 'success',
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          action: (key) => (
            <IconButton onClick={() => { closeSnackbar(key) }}>
              <CloseIcon />
            </IconButton>
          )
        });
      }
    ).catch(
      (error) => {
        setLoading(false);
        setSendResponse(false);
        enqueueSnackbar(`${error.response.data.error}`, {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          action: (key) => (
            <IconButton onClick={() => { closeSnackbar(key) }}>
              <CloseIcon />
            </IconButton>
          )
        });
      }
    );
  }

  const UpdateUser = async (id, body) => {
    setLoading(true);
    await PutUser(id, body).then(
      (result) => {
        const { data } = result;
        setLoading(false);
        setSendResponse(true);
        enqueueSnackbar(`${data.message}`, {
          variant: 'success',
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          action: (key) => (
            <IconButton onClick={() => { closeSnackbar(key) }}>
              <CloseIcon />
            </IconButton>
          )
        });
      }
    ).catch(
      (error) => {
        setLoading(false);
        setSendResponse(false);
        enqueueSnackbar(`${error.response.data.error}`, {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          action: (key) => (
            <IconButton onClick={() => { closeSnackbar(key) }}>
              <CloseIcon />
            </IconButton>
          )
        });
      }
    );
  }

  const UpdateProfile = async (id, body) => {
    setLoading(true);
    await PutUser(id, body).then(
      (result) => {
        const { data } = result;
        setLoading(false);
        setSendResponse(true);
        dispatch(onUser(data.result));
        enqueueSnackbar(`${data.message}`, {
          variant: 'success',
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          action: (key) => (
            <IconButton onClick={() => { closeSnackbar(key) }}>
              <CloseIcon />
            </IconButton>
          )
        });
      }
    ).catch(
      (error) => {
        setLoading(false);
        setSendResponse(false);
        enqueueSnackbar(`${error.response.data.error}`, {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          action: (key) => (
            <IconButton onClick={() => { closeSnackbar(key) }}>
              <CloseIcon />
            </IconButton>
          )
        });
      }
    );
  }

  const ChangeStatus = async (id) => {
    try {
      const { data } = await DeleteUser(id);
    } catch (error) {
      // toast.error(error.response.data.error);
    }
  }

  const ResetPassword = async (id) => {
    await GetResetPass(id).then(
      (result) => {
        const { data } = result;
        setLoading(false);
        setSendResponse(true);
        enqueueSnackbar(`${data.message}`, {
          variant: 'success',
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          action: (key) => (
            <IconButton onClick={() => { closeSnackbar(key) }}>
              <CloseIcon />
            </IconButton>
          )
        });
      }
    ).catch(
      (error) => {
        setLoading(false);
        setSendResponse(false);
        enqueueSnackbar(`${error.response.data.error}`, {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          action: (key) => (
            <IconButton onClick={() => { closeSnackbar(key) }}>
              <CloseIcon />
            </IconButton>
          )
        });
      }
    );
  }

  return { user, users, loading, loadingInfo, sendResponse, usersData, FindUser, createUser, UpdateUser, UpdateProfile, ChangeStatus, ResetPassword }
}