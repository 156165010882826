import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { GetRules, GetRule, PostRules, PutRules, DeleteRules } from '../../service';

export const useRules = () =>{

  const inRules = async () => {
    try {
      const { data:{ success, message, result } } = await GetRules();
      return { success, result };
    } catch (error) {
      return error;
    }
  }

  const inRule = async (id) => {
    try {
      const { data:{ success, message, result } } = await GetRule(id);
      return { success, result };
    } catch (error) {
      return error;
    }
  }

  const onRules = async (body) => {
    try {
      const { data:{ success, message, result } } = await PostRules(body);
      return { success, result };
    } catch (error) {
      return error;
    }
  }

  const upRules = async (id, body) => {
    try {
        const { data:{ success, message, result } } = await PutRules(id, body);
        return { success, result };
    } catch (error) {
        return error;
    }
  }

  const deRules = async (id) => {
    try {
        const { data:{ success, message, result } } = await DeleteRules(id);
        return { success, result };
    } catch (error) {
        return error;
    }
  }

  return {
    inRules,
    inRule,
    onRules,
    upRules,
    deRules
  }
}
