/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Box, TextField, LinearProgress } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { useAuthSap } from '../../hooks';

const filter = createFilterOptions();
export default function ItemCodeSearchForm(props){
  const { send } = props;
  const { collectionQuery, ItemsQuery } = useAuthSap();
  const [value, setValue] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      await ItemsQuery('');
      setLoading(false);
    };
    fetchData();
  }, []);
  
  if (loading) {
    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgress />
      </Box>
    );
  }

  return (
    <Autocomplete
      value={value}
      onChange={(event, newValue) => {
        if (typeof newValue === 'string') {
          setValue({ ItemCode: newValue });
          send(newValue);
        } else if (newValue && newValue.inputValue) {
          setValue({ ItemCode: newValue.inputValue });
          send(newValue);
        } else {
          setValue(newValue);
          send(newValue);
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params).slice(0, 50);
        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id="ItemCode"
      options={collectionQuery}
      limitTags={10}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option;
        }
        if (option.inputValue) {
          return option.inputValue;
        }
        return option.ItemCode;
      }}
      renderOption={(props, option) => <li {...props}>{option.ItemCode} - {option.ItemName}</li>}
      fullWidth
      freeSolo
      renderInput={(params) => <TextField {...params} label="Código de Artículo" />}
    />
  );
}
