import axios from '../config/config';

export const PostAuth = (body) => axios.post(`auth`, body);
export const GetAuth = () => axios.get(`auth`);

// Logs
export const GetLogs = () => axios.get(`log`);
export const GetLog = (id) => axios.get(`log/${id}`);

// Users
export const GetUsers = () => axios.get(`user`);
export const GetUser = (id) => axios.get(`user/${id}`);
export const PostUser = (body) => axios.post(`user`, body);
export const PutUser = (id, body) => axios.put(`user/${id}`, body);
export const DeleteUser = (id) => axios.delete(`user/${id}`);
export const GetResetPass = (id) => axios.get(`user/reset-password/${id}`);

// Business
export const GetBusiness = () => axios.get(`business`);
export const GetBusines = (id) => axios.get(`business/${id}`);
export const PostBusiness = (body) => axios.post(`business`, body);
export const PutBusiness = (id, body) => axios.put(`business/${id}`, body);
// BusinessUser
export const FindRelationUserBusiness = (id) => axios.get(`user-business/user/${id}`);
export const PostBusinessUser = (body) => axios.post(`user-business`, body);
export const DeleteBusinessUser = (id) => axios.delete(`user-business/${id}`);

// Rules
export const GetRules = () => axios.get(`rules`);
export const GetRule = (id) => axios.get(`rules/${id}`);
export const PostRules = (body) => axios.post(`rules`, body);
export const PutRules = (id, body) => axios.put(`rules/${id}`, body);
export const DeleteRules = (id) => axios.delete(`rules/${id}`);

// CenterCost
export const GetCenterCosts = () => axios.get(`center-cost`);
export const GetCenterCost = (id) => axios.get(`center-cost/${id}`);
export const FindCenterCostByBusiness = (id) => axios.get(`center-cost/business/${id}`);
export const PostCenterCost = (body) => axios.post(`center-cost`, body);
export const PutCenterCost = (id, body) => axios.put(`center-cost/${id}`, body);
// CenterCostUser
export const FindRelationUserCenterCost = (id) => axios.get(`user-center-cost/user/${id}`);
export const PostCenterCostUser = (body) => axios.post(`user-center-cost`, body);
export const DeleteCenterCostUser = (id) => axios.delete(`user-center-cost/${id}`);

// ApproverUser
export const ApproversAll = () => axios.get(`sap/approvals-approver`);

export const UserApproverFind = (id) => axios.get(`sap/approvals-users/${id}`);
export const UserApproverRelation = (body) => axios.post(`sap/approvals-users`, body);
export const UserApproverDelete = (id) => axios.delete(`sap/approvals-users/${id}`);

// PurchaseRequest purchase-request-approvals
export const purchaseRequestAll = (query) => axios.get(`sap/purchase-request-alls${query}`);

export const purchaseRequestApprovalFindAll = (query) => axios.get(`sap/purchase-request-approvals${query}`);
export const purchaseRequestApproval = (query, body) => axios.post(`sap/purchase-request-approvals${query}`, body);
export const purchaseRequestApprovalUpdate = (id, query, body) => axios.put(`sap/purchase-request-approvals/${id}${query}`, body);

// export const POSTPurchaseRequests = (query, body) => axios.post(`sap/purchase-request${query}`, body);
export const POSTPurchaseRequests = (query, body) => axios.post(`sap/purchase-request-approvals${query}`, body);
export const PUTPurchaseRequests = (docEntry, body, query) => axios.put(`sap/purchase-request/${docEntry}${query}`, body);

export const GetCenterCostQuery = (id, query) => axios.get(`sap/centerCost/${id}${query}`);
export const GetItemsQuery = (query) => axios.get(`sap/items${query}`);
export const GetTasxesQuery = (query) => axios.get(`sap/taxes${query}`);
export const GetItemsFindQuery = (itemcode, query) => axios.get(`sap/items/${itemcode}${query}`);
export const GetBussinessPartnersQuery = (query) => axios.get(`sap/bussinessPartners${query}`);
export const GetWarehousesQuery = (query) => axios.get(`sap/warehouses${query}`);
export const GetCashFlowQuery = (query) => axios.get(`sap/cashFlow${query}`);

export const GETPurchaseRequests = (query) => axios.get(`sap/purchase-request${query}`);
export const FindPurchaseRequets = (docEntry, query) => axios.get(`sap/purchase-request/${docEntry}${query}`);
export const FindDraft = (docEntry, query) => axios.get(`sap/purchase-request/draft/${docEntry}${query}`);
export const FindPRDetail = (docNum, query) => axios.get(`sap/purchase-request/detail/${docNum}${query}`);
export const ExportPurchaseRequests = (query) => axios.get(`sap/purchase-request/report${query}`);

// REQUEST PENDIENTES O RECHAZADAS
export const GETDrafts = (query) => axios.get(`sap/purchase-request-approvals${query}`);
export const GETDraftUsrs = (query) => axios.get(`sap/purchase-request-approval-usrs${query}`);
export const GETDraftDetail = (id, uuid) => axios.get(`sap/purchase-request-approvals/${id}/${uuid}`);


// Applicants
export const GetApplicants = () => axios.get(`applicants`);
export const PDFPurchaseRequests = (docEntry, query) => axios.get(`sap/purchase-request/pdf/${docEntry}${query}`);
export const SolpedPurchaseRequests = (docEntry) => axios.get(`purchase-request/solped/${docEntry}`);
// Notification
export const GETNotification = (query) => axios.get(`notification/purchase-request?${query}`);