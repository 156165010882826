import { useState, useEffect } from 'react';
import { GetLogs, GetLog } from '../../service';

export const useLogs = () =>{
  const [ logs, setLogs ] = useState([]);
  const [ log, setLog ] = useState([]);
  const [ loading, setLoading ] = useState(false);
  const [ loadingInfo, setLoadingInfo ] = useState(false);
  // const [ sendResponse, setSendResponse ] = useState(false);

  async function logsData(){
    try {
      const { data } = await GetLogs();
      setLoading(false);
      setLoadingInfo(false);
      setLogs(data.result);
    } catch (err) {
      setLoading(true);
    }
  }

  useEffect(() => {
    logsData();
  }, []);

  const FindLog = async (id) => {
    setLoading(true);
    await GetLog(id).then(
      (result) => {
        const { data } = result;
        setLoading(false);
        setLog(data.result);
      }
    ).catch(
      (error) => {
        console.log(error);
        setLoading(false);
      }
    );
  }

  return { logs, log, loading, loadingInfo, logsData, FindLog }
}