/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import moment from 'moment';
// @mui
import { Stack, Popover, TableRow, MenuItem, TableCell, Typography, IconButton  } from '@mui/material';
import Iconify from '../../iconify';
import Label from '../../label';
import Modal from '../../modal';
import { renderName } from '../../../utils';
import { DetailsLog } from '../../details';

export default function BodyLog(props){
  const { filtered, page, rowsPerPage, emptyRows } = props;

  const [data, setData] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleOpenMenu = (event, detail) => {
    setData(detail);
    setOpenMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenu(null);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  }

  const handleCloseModal = () => {
    setData(null);
    setOpenModal(false);
    setOpenMenu(false);
  };

  return (
    <>
      {
        filtered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
          const { id, description, api, method, code, created_at, userinfo } = row;
          const formattedDate = moment(created_at).format("DD/MM/YYYY");

          return (
            <TableRow hover key={id} tabIndex={-1}>
              <TableCell component="th" scope="row">
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Typography variant="subtitle2" noWrap>
                    {id}
                  </Typography>
                </Stack>
              </TableCell>
              <TableCell align="left">
                {description}
              </TableCell>
              <TableCell align="left">
                {renderName(userinfo?.name, userinfo?.lastNameP, userinfo?.lastNameM)}
              </TableCell>
              <TableCell align="left">
                <Label color={'info'}>
                  {api}
                </Label>
              </TableCell>
              <TableCell align="left">
                <Label color={'info'}>
                  {method}
                </Label>
              </TableCell>
              <TableCell align="left">
                {
                  code === '200' ? (
                  <Label color={'success'}>
                    {code}
                  </Label>
                  ): code === '400' ? (
                    <Label color={'warning'}>
                      {code}
                    </Label>
                  ): code === '500' ? (
                    <Label color={'error'}>
                      {code}
                    </Label>
                  ):null
                }
              </TableCell>
              <TableCell align="left">
                <Label color={'info'}>
                  {formattedDate}
                </Label>
              </TableCell>
              <TableCell align="right">
                <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, row)}>
                <Iconify icon={'eva:more-vertical-fill'} />
                </IconButton>
              </TableCell>
            </TableRow>
          );
        })
      }

      {
        emptyRows > 0 && (
          <TableRow style={{ height: 53 * emptyRows }}>
            <TableCell colSpan={7} />
          </TableRow>
        )
      }

      <Popover
        open={Boolean(openMenu)} 
        anchorEl={openMenu} 
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75
            }
          }
        }}
      >
        <MenuItem onClick={handleOpenModal}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Detalles
        </MenuItem>
      </Popover>

      <Modal title="Detalle de LOG" maxWidth={'xs'} open={openModal} onClose={handleCloseModal}>
        <DetailsLog data={data}/>
      </Modal>
    </>
  )
}
