import React, { useEffect, useState } from 'react';
import { Card, Stack, Grid, Button, Container, Typography, Skeleton, } from '@mui/material';
import Iconify from '../../components/iconify';
import Modal from '../../components/modal';
import TableCustomer from '../../components/table/TableCustomer';
import { useBusiness, useRules } from '../../hooks';
import { BusinessForm, RulesForm } from '../../components/forms';

const TABLE_HEAD = [
  { id: 'description', label: 'Descripción', alignRight: false },
  { id: 'ends_in', label: 'Termina', alignRight: false },
  { id: 'go_with', label: 'Van', alignRight: false },
  { id: 'status', label: 'Estado', alignRight: false },
  { id: 'createdAt', label: 'Registro', alignRight: false },
  { id: '' },
];
export default function RulesPage() {
  const { inRules } = useRules();
  const [ rules, setRules ] = useState([]);
  const [ load, setLoad ] = useState(true);
  const [ open, setOpen ] = useState(false);

  const handleCloseModal = () => {
    setOpen(false);
  };

  const fetchRules = async () => {
    try {
      const { result } = await inRules();
      console.log(result);
      setRules(result);
      setLoad(false);
      handleCloseModal();
    } catch (error) {
      setLoad(false);
      console.log(error);
    }
  }

  useEffect(() => {
    fetchRules();
  }, []);

  return (
    <Container maxWidth="xl">
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Reglas de validaciones
        </Typography>
        {
          load ? 
            <Stack spacing={1}>
              <Skeleton variant="rounded" width={153} height={36} />
            </Stack>
          : 
          <Button variant="contained" onClick={() => setOpen(true)} startIcon={<Iconify icon="eva:plus-fill" />}>
            Nueva regla
          </Button>
        }
      </Stack>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card >
            {
              load ? 
              <Stack spacing={1}>
                <Skeleton variant="rounded" width={1200} height={350} />
              </Stack>
              : 
              <TableCustomer tableHead={TABLE_HEAD} typeTable="rules" refresh={fetchRules} data={rules} />
            }
          </Card>
        </Grid>
      </Grid>
      <Modal title="Nueva regla" maxWidth={'xs'} open={open} onClose={handleCloseModal} >
        <RulesForm reload={fetchRules} />
      </Modal>
    </Container>
  )
}
