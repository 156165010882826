import { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { ValidationError } from 'yup';
// @mui
import { Stack, IconButton, InputAdornment, TextField, CircularProgress } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';
import Iconify from '../../iconify';
// @utils
import { LoginSchema } from '../../../utils';
// @hooks
import { useAuthStore, useForm } from '../../../hooks';

export default function LoginForm() {
  const navigate = useNavigate();
  const record = localStorage.getItem('email');
  const { formState, errors, setErrors, inputChange } = useForm({email: record != null ? record:'', password: ''});
  const { status, login, logout } = useAuthStore();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [ showPassword, setShowPassword ] = useState(false);

  const isLoading = useMemo( () => status === 'checking', [status]);

  const onSubmit = async (event) => {
    event.preventDefault();
    try {
      await LoginSchema.validate(formState, { abortEarly: false });
      localStorage.setItem('email', formState.email);
      await login(formState);
      setErrors({});
      navigate('/inicio', { replace: true });
    } catch (err) {
      enqueueSnackbar(`Correo y/o Contraseña incorrectas`, {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
        action: (key) => (
          <IconButton onClick={() => { closeSnackbar(key) }}>
            <CloseIcon />
          </IconButton>
        )
      });
      if (err instanceof ValidationError) {
        setErrors(err.inner.reduce((acc, curr) => {
          acc[curr.path] = curr.message;
          return acc;
        }, {}));
      }
      await logout();
    }
  };

  return (
    <>
      <form onSubmit={onSubmit}>
        <Stack spacing={3}>
          <TextField 
            name="email"
            label="Correo Electrónico"
            onChange={inputChange}
            value={formState.email}
            disabled={isLoading} 
            error={Boolean(errors.email)}
            helperText={errors.email}
            autoComplete="off"/>
          <TextField
            name="password"
            label="Contraseña"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={inputChange}
            value={formState.password}
            disabled={isLoading}
            error={Boolean(errors.password)}
            helperText={errors.password}
            autoComplete="off"
          />
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="center" sx={{my: 2}}>
          <LoadingButton type="submit" sx={{minWidth: "150px"}} size="large" variant="contained" disabled={isLoading}>
            {!isLoading ? ('Ingresar'):('Ingresando')}
            {isLoading ? (<CircularProgress size={20} color="inherit" style={{ marginLeft: '10px' }}/>):('')}
          </LoadingButton>
        </Stack>
      </form>
    </>
  );
}
