import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { FindRelationUserCenterCost, PostCenterCostUser, DeleteCenterCostUser } from '../../service';

export const useCenterCostUser = () =>{
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [ centerCostRelation, setCenterCostRelation ] = useState([]);
  const [ loading, setLoading ] = useState(false);
  const [ skeleting, setSkeleting ] = useState(false);

  const RelationCenterCostUser = async (body) => {
    setLoading(true);
    await PostCenterCostUser(body).then(
      (result) => {
        const { data } = result;
        setLoading(false);
        enqueueSnackbar(`${data.message}`, {
          variant: 'success',
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          action: (key) => (
            <IconButton onClick={() => { closeSnackbar(key) }}>
              <CloseIcon />
            </IconButton>
          )
        });
      }
    ).catch(
      (error) => {
        setLoading(false);
        enqueueSnackbar(`${error.response.data.error}`, {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          action: (key) => (
            <IconButton onClick={() => { closeSnackbar(key) }}>
              <CloseIcon />
            </IconButton>
          )
        });
      }
    );
  }

  const FindRelationCenterCostUser = async (id) => {
    setSkeleting(true);
    await FindRelationUserCenterCost(id).then(
      (result) => {
        const { data } = result;
        setCenterCostRelation(data.result);
        setSkeleting(false);
      }
    ).catch(
      (error) => {
        setSkeleting(false);
        enqueueSnackbar(`${error.response.data.error}`, {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          action: (key) => (
            <IconButton onClick={() => { closeSnackbar(key) }}>
              <CloseIcon />
            </IconButton>
          )
        });
      }
    );
  }

  const DeleteRelationCenterCostUser = async (id) => {
    await DeleteCenterCostUser(id).then(
      (result) => {
        const { data } = result;
        setLoading(false);
        enqueueSnackbar(`${data.message}`, {
          variant: 'warning',
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          action: (key) => (
            <IconButton onClick={() => { closeSnackbar(key) }}>
              <CloseIcon />
            </IconButton>
          )
        });
      }
    ).catch(
      (error) => {
        setLoading(false);
        enqueueSnackbar(`${error.response.data.error}`, {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          action: (key) => (
            <IconButton onClick={() => { closeSnackbar(key) }}>
              <CloseIcon />
            </IconButton>
          )
        });
      }
    );
  }

  return {
    centerCostRelation,
    loading,
    skeleting,
    RelationCenterCostUser,
    FindRelationCenterCostUser,
    DeleteRelationCenterCostUser,
  }
}

