/* eslint-disable import/order */
/* eslint-disable react/prop-types */
import React from 'react';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import { ProfileForm } from '../../../../components/forms';
import Label from '../../../../components/label';

import moment from 'moment';

export default function UserProfile(props){
    const { usr } = props;
    const formattedDate = moment(usr?.updated_at).format("DD/MM/YYYY HH:mm:ss A");

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={4} md={4}>
                <Card>
                    <CardContent>
                        <Grid container>
                            <Grid item xs={6}>
                                <Typography variant="h6" align="left">Cuenta:</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="body2" align="right">
                                    <Label color={(usr?.status === 1 ? 'success':'error')}>{(usr?.status === 1 ? 'Activo':'Inactivo')}</Label>
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container mt={7.5}>
                            <Grid item xs={6}>
                                <Typography variant="h6" align="left">Verificado:</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="body2" align="right">
                                    <Label color={(usr?.isVerified === 1 ? 'success':'error')}>{(usr?.isVerified === 1 ? 'Sí':'No')}</Label>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2">
                                    La cuenta se verifico la primera vez, que se inicio sesión dentro de la plataforma.
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container mt={7.5}>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2" align="center">
                                    La cuenta se actualizado por última vez.
                                </Typography>
                                <Typography variant="subtitle2" align="center">
                                    <Label color="info">{formattedDate}</Label>
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
                <Card>
                    <CardContent>
                        <ProfileForm usr={usr}/>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}
