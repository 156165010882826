/* eslint-disable no-unneeded-ternary */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import {
    styled,
    TextField,
    Button,
    Paper,
    Grid,
    CircularProgress,
    IconButton,
    MenuItem,
    Select,
    FormHelperText
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import * as yup from 'yup';
import { useBusiness, useCenterCost } from '../../../hooks';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function CenterCostForm(props){
    const { centerCost, onSubmit } = props;
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { business } = useBusiness();
    const { sendResponse, loading, createCenterCost, updateCenterCost } = useCenterCost();
    const [ errors, setErrors ] = useState([]);
    const [ formData, setFormData ] = useState({
        business: centerCost?.business_id || 0, 
        dimension: centerCost?.dimension || 0, 
        code: centerCost?.code || '', 
        description: centerCost?.description || ''
    });

    useEffect(() => { 
        if (sendResponse) {
            onSubmit(true); 
        }
    }, [sendResponse, onSubmit]);
    const CenterCostSchema = yup.object().shape({
        code: yup
            .string()
            .min(1, 'Mínimo, 1 digito')
            .required('Código es requerido'),
        dimension: yup
            .number()
            .min(1, 'Mínimo, 1 digito')
            .required('Dimensión es requerido'),
        description: yup
            .string()
            .min(3, 'Mínimo, 3 carácteres')
            .required('Descripción es requerida'),
        business: yup
            .number()
            .oneOf(business.map(item => item.id), 'Selecciona una empresa')
            .required('La empresa es requerida'),
    });

    const inputChange = (event) => {
        const { name, value } = event.target;
        let processedValue = value;

        if (name === 'business') {
            processedValue = value;
        } else if (name === 'dimension') {
            processedValue = Number(value);
        } else if (name === 'code') {
            processedValue = value.toUpperCase();
        } else if (name === 'description') {
            processedValue = value.toUpperCase();
        }
        setFormData((prevState) => ({
            ...prevState,
            [name]: processedValue,
        }));
        CenterCostSchema.validateAt(name, { [name]: processedValue })
        .then(() => {
            setErrors({ ...errors, [name]: "" });
        })
        .catch((err) => {
            setErrors({ ...errors, [name]: err.errors[0] });
        });
    };

    const submitForm = async () => {
        try {
            await CenterCostSchema.validate(formData, { abortEarly: false });
            if (centerCost === null) {
                await createCenterCost(formData);
            } else {
                await updateCenterCost(centerCost?.id, formData);
            }
        } catch (err) {
            enqueueSnackbar(`Formulario incompleto!`, {
                variant: 'error',
                autoHideDuration: 3000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
                action: (key) => (
                    <IconButton onClick={() => { closeSnackbar(key) }}>
                      <CloseIcon />
                    </IconButton>
                )
            });
            if (err instanceof yup.ValidationError) {
                setErrors(err.inner.reduce((acc, curr) => {
                    acc[curr.path] = curr.message;
                    return acc;
                }, {}));
            }
        }
    };

    return (
        <Grid container>
            {
                business.length > 0 ? 
                <>
                    <Grid item xs={12} sm={12} md={12}>
                        <Item>
                            <Select 
                                autoFocus
                                id="business"
                                name="business"
                                size="small"
                                variant="outlined"
                                onChange={inputChange}
                                value={formData.business}
                                error={Boolean(errors.business)}
                                fullWidth
                            >
                                <MenuItem value={0}>Selecciona una empresa</MenuItem>
                                {
                                    business.map(
                                        (item, index) => (
                                            <MenuItem key={index} value={item.id}>{item.businessName}</MenuItem>
                                        )
                                    )
                                }
                            </Select>
                            <FormHelperText error>{errors.business}</FormHelperText>
                        </Item>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Item>
                            <TextField 
                                id="dimension"
                                name="dimension"
                                label="Dimension" 
                                type="number" 
                                size="small" 
                                variant="outlined" 
                                fullWidth 
                                value={formData.dimension}
                                onChange={inputChange}
                                error={Boolean(errors.dimension)}
                                helperText={errors.dimension}
                                autoComplete="off" />
                        </Item>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Item>
                            <TextField 
                                id="code"
                                name="code"
                                label="Código" 
                                type="text" 
                                size="small" 
                                variant="outlined" 
                                fullWidth 
                                value={formData.code}
                                onChange={inputChange}
                                error={Boolean(errors.code)}
                                helperText={errors.code}
                                autoComplete="off" />
                        </Item>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Item>
                            <TextField 
                                id="description" 
                                name="description"
                                label="Descripción"
                                type="text"
                                size="small" 
                                variant="outlined" 
                                fullWidth
                                onChange={inputChange}
                                value={formData.description}
                                error={Boolean(errors.description)}
                                helperText={errors.description}
                                autoComplete="off" />
                        </Item>
                    </Grid>
                    <Grid item xs={12} style={{display:'flex', justifyContent:'flex-start'}}>
                        <Item>
                            <Button variant="contained" onClick={submitForm} disabled={loading}>
                                {centerCost == null ? 'Registrar':'Actualizar'}
                                {loading ? <CircularProgress size={20} color="inherit" style={{ marginLeft: '10px' }}/>:null}
                            </Button>                   
                        </Item>
                    </Grid>
                </>:null
            }
        </Grid>
    )
}
