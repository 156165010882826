import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'; 
import { useSnackbar } from 'notistack';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { 
    GETPurchaseRequests, 
    GETDrafts, 
    POSTPurchaseRequests,
    purchaseRequestApproval,
    PUTPurchaseRequests, 
    PDFPurchaseRequests, 
    FindDraft, ExportPurchaseRequests, SolpedPurchaseRequests, FindPurchaseRequets, FindPRDetail } from '../../service';

export const usePurchases = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { user } = useSelector(state => state.user);
    const [ docPurchases, setDocPurchase ] = useState([]);
    const [ docDrafts, setDocDrafts ] = useState([]);
    const [ pdfDocument, setPdfDocument ] = useState('');
    const [ purchaseR, setPurchaseR] = useState(null);
    const [ purchaseRD, setPurchaseRD] = useState(null);
    const [ excelBlob, setExcelBlob] = useState(null);
    const [ docSolped, setDocSolped] = useState(null);
    const [ docCreate, setDocCreate ] = useState(false);
    const [ docUpdate, setDocUpdate ] = useState(false);
    const [ msgError, setMsgError ] = useState(null);
    
    const GetPurchaseRequest = async () => {
        try {
            const { data } = await GETPurchaseRequests(`?business=${localStorage.getItem('business')}`);
            setDocPurchase(data.result);
        } catch (err) {
            console.log(err);
        }
    }

    const GetDrafts = async () => {
        try {
            const { data } = await GETDrafts(`?business=${localStorage.getItem('business')}`);
            setDocDrafts(data.result);
        } catch (err) {
            console.log(err);
        }
    }

    const PDFDocument = async (docEntry) => {
        enqueueSnackbar(`Generando PDF`, {
            variant: 'info',
            autoHideDuration: 3000,
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
            },
            action: (key) => (
                <IconButton onClick={() => { closeSnackbar(key) }}>
                    <CloseIcon />
                </IconButton>
            )
        });
        try {
            const { data } = await PDFPurchaseRequests(docEntry, `?business=${localStorage.getItem('business')}`);
            setPdfDocument(data.result);
            enqueueSnackbar(`PDF Generado`, {
                variant: 'success',
                autoHideDuration: 3000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
                action: (key) => (
                    <IconButton onClick={() => { closeSnackbar(key) }}>
                        <CloseIcon />
                    </IconButton>
                )
            });
        } catch (err) {
            console.log(err);
        }
    }

    const PurchaseRequestFind = async (docEntry) => {
        try {
            const { data } = await FindPurchaseRequets(docEntry, `?business=${localStorage.getItem('business')}`);
            setPurchaseR(data.result);
        } catch (err) {
            enqueueSnackbar(`${err.response.data.error}`, {
                variant: 'error',
                autoHideDuration: 3000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
                action: (key) => (
                    <IconButton onClick={() => { closeSnackbar(key) }}>
                        <CloseIcon />
                    </IconButton>
                )
            });
        }
    }

    const DraftFind = async (docEntry) => {
        try {
            const { data } = await FindDraft(docEntry, `?business=${localStorage.getItem('business')}`);
            setPurchaseR(data.result);
        } catch (err) {
            enqueueSnackbar(`${err.response.data.error}`, {
                variant: 'error',
                autoHideDuration: 3000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
                action: (key) => (
                    <IconButton onClick={() => { closeSnackbar(key) }}>
                        <CloseIcon />
                    </IconButton>
                )
            });
        }
    }

    const PRDetail = async (docNum) => {
        try {
            const { data } = await FindPRDetail(docNum, `?business=${localStorage.getItem('business')}`);
            setPurchaseRD(data.result);
        } catch (err) {
            enqueueSnackbar(`${err.response.data.error}`, {
                variant: 'error',
                autoHideDuration: 3000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
                action: (key) => (
                    <IconButton onClick={() => { closeSnackbar(key) }}>
                        <CloseIcon />
                    </IconButton>
                )
            });
        }
    }

    const ReportDocument = async () => {
        enqueueSnackbar(`Generando Reporte`, {
            variant: 'info',
            autoHideDuration: 3000,
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
            },
            action: (key) => (
                <IconButton onClick={() => { closeSnackbar(key) }}>
                    <CloseIcon />
                </IconButton>
            )
        });
        try {
            const { data } = await ExportPurchaseRequests(`?business=${localStorage.getItem('business')}`);
            setExcelBlob(data.result);
            enqueueSnackbar(`Reporte Generado`, {
                variant: 'success',
                autoHideDuration: 3000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
                action: (key) => (
                    <IconButton onClick={() => { closeSnackbar(key) }}>
                        <CloseIcon />
                    </IconButton>
                )
            });
        } catch (err) {
            console.log(err);
        }
    }

    const SolpedDocument = async (docEntry) => {
        try {
            const { data } = await SolpedPurchaseRequests(docEntry);
            setDocSolped(data.result);
        } catch (err) {
            console.log(err);
        }
    }

    const CreatePurchaseRequest = async (body) => {
        setDocCreate(false);
        try {
            const { data } = await purchaseRequestApproval(`?business=${localStorage.getItem('business')}`, body);
            setDocCreate(true);
            enqueueSnackbar(`${data.message}`, {
                variant: 'success',
                autoHideDuration: 3000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
                action: (key) => (
                    <IconButton onClick={() => { closeSnackbar(key) }}>
                        <CloseIcon />
                    </IconButton>
                )
            });
        } catch (err) {
            setDocCreate(false);
            setMsgError(err.response.data.error);
            enqueueSnackbar(`${err.response.data.error}`, {
                variant: 'error',
                autoHideDuration: 3000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
                action: (key) => (
                    <IconButton onClick={() => { closeSnackbar(key) }}>
                        <CloseIcon />
                    </IconButton>
                )
            });
        }
    }

    const UpdatePurchaseRequest = async (docEntry, body) => {
        setDocUpdate(false);
        try {
            const { data } = await PUTPurchaseRequests(docEntry, body,`?business=${localStorage.getItem('business')}`);
            setDocUpdate(true);
            enqueueSnackbar(`${data.message}`, {
                variant: 'success',
                autoHideDuration: 3000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
                action: (key) => (
                    <IconButton onClick={() => { closeSnackbar(key) }}>
                        <CloseIcon />
                    </IconButton>
                )
            });
        } catch (err) {
            setDocUpdate(false);
            setMsgError(err.response.data.error);
            enqueueSnackbar(`${err.response.data.error}`, {
                variant: 'error',
                autoHideDuration: 3000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
                action: (key) => (
                    <IconButton onClick={() => { closeSnackbar(key) }}>
                        <CloseIcon />
                    </IconButton>
                )
            });
        }
    }

    return { 
        docPurchases,
        docDrafts,
        msgError,
        docUpdate,
        pdfDocument,
        excelBlob,
        docCreate,
        docSolped,
        purchaseR,
        purchaseRD,
        setExcelBlob,
        DraftFind,
        setMsgError,
        GetPurchaseRequest,
        GetDrafts,
        CreatePurchaseRequest,
        PDFDocument,
        ReportDocument,
        SolpedDocument,
        PurchaseRequestFind,
        PRDetail,
        UpdatePurchaseRequest
    }
}
