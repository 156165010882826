/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import ArrowRight from '@untitled-ui/icons-react/build/esm/ArrowRight';
import { NavLink as RouterLink } from 'react-router-dom';
import moment from 'moment';
import { Stack, Popover, TableRow, Tooltip, SvgIcon, IconButton, MenuItem, TableCell, Typography } from '@mui/material';
import { UserForm, BusinessUserForm, CenterCostUserForm, ApproverUserForm } from '../../forms';
import Iconify from '../../iconify';
import Label from '../../label';

export default function BodyApprovalPurchaseAdmin({ filtered, page, rowsPerPage, emptyRows, refresh, openBackdrop }){

  return (
    <>
      {
        filtered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
          const { id, docNum, uuid, sociedad, solicitante, comments, status, createdAt  } = row;
          return (
            <TableRow hover key={id} tabIndex={-1}>
                <TableCell component="th" scope="row">
                  <Typography variant="subtitle2" noWrap>
                    {id}
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Label color={docNum ? 'info':'warning'}>
                    {docNum || "N/A"}
                  </Label>
                </TableCell>
                <TableCell align="left">
                    {sociedad}
                </TableCell>
                <TableCell align="left">
                    {solicitante}
                </TableCell>
                <TableCell align="left">
                    {comments}
                </TableCell>
                <TableCell align="left">
                  {
                    status === 'autorizado' ? 
                    <Label color={'success'}>
                      Aprobado
                    </Label>
                    : status === 'pendiente' ?
                    <Label color={'warning'}>
                      Pendiente
                    </Label>
                    : status === 'reproceso' ?
                    <Label color={'warning'}>
                      Reprocesar
                    </Label>
                    :
                    <Label color={'error'}>
                      Rechazado
                    </Label>
                  }
                </TableCell>
                <TableCell align="left">
                  {moment(createdAt).format("DD/MM/YYYY")}
                </TableCell>
            </TableRow>
          );
        })
      }

      {
        emptyRows > 0 && (
          <TableRow style={{ height: 53 * emptyRows }}>
            <TableCell colSpan={7} />
          </TableRow>
        )
      }
    </>
  )
}
