import React, {useState} from 'react';
import { Card, Stack, Grid, Button, Container, Typography, Skeleton, } from '@mui/material';
import Iconify from '../../components/iconify';
import Modal from '../../components/modal';
import TableCustomer from '../../components/table/TableCustomer';
import { useCenterCost } from '../../hooks';
import { CenterCostForm } from '../../components/forms';

const TABLE_HEAD = [
  { id: 'code', label: 'Código', alignRight: false },
  { id: 'description', label: 'Descripción', alignRight: false },
  { id: 'status', label: 'Estado', alignRight: false },
  { id: 'created_at', label: 'Registro', alignRight: false },
  { id: '' },
];

export default function CenterCostPage() {

  const { centerCost, loadingInfo, centerCostData } = useCenterCost();
  const [ open, setOpen ] = useState(false);

  const handleCloseModal = () => {
    setOpen(false);
  };

  const onSubmit = (on) => {
    if (on) {
      setOpen(false);
      centerCostData();
    }
  }

  return (
    <Container maxWidth="xl">
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Centro de Costos
        </Typography>
        {
          loadingInfo ? 
          <Stack spacing={1}>
            <Skeleton variant="rounded" width={153} height={36} />
          </Stack>
          : 
          <Button variant="contained" onClick={() => setOpen(true)} startIcon={<Iconify icon="eva:plus-fill" />}>
            Nuevo centro costo
          </Button>
        }
      </Stack>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card >
            {
              loadingInfo ? 
              <Stack spacing={1}>
                <Skeleton variant="rounded" width={1200} height={350} />
              </Stack>
              :
              <TableCustomer tableHead={TABLE_HEAD} typeTable="centerCost" refresh={onSubmit} data={centerCost} />
            }
          </Card>
        </Grid>
      </Grid>
      <Modal title="Nuevo Centro de Costo" maxWidth={'xs'} open={open} onClose={handleCloseModal} >
        <CenterCostForm centerCost={null} onSubmit={onSubmit} refresh={onSubmit}/>
      </Modal>
    </Container>
  )
}
