/* eslint-disable dot-notation */
import axios from 'axios';
// import https from 'https-browserify';
// import http from 'stream-http';


// const agent = new https.Agent({rejectUnauthorized: false});

const {REACT_APP_SAP_URL} = process.env;

const api = axios.create({
  baseURL: `${REACT_APP_SAP_URL}`,
  // httpsAgent: agent,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'Prefer': 'odata.maxpagesize=0'
  },
});

export default api;