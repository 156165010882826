/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import { 
  SvgIcon, 
  Card,
   Container, 
   Typography,
    Grid, 
    Stack, 
    Skeleton, 
    Button, 
    CircularProgress,
    FormHelperText,
    MenuItem,
    Select,
    CardContent
  } from '@mui/material';
import Download01 from '@untitled-ui/icons-react/build/esm/Download01';
import { exportToExcel } from 'react-json-to-excel';
import { useAuthStore, useRequestApproval } from '../../hooks';
import TableCustomer from '../../components/table';

const TABLE_HEAD = [
  { id: 'id', label: '#', alignRight: false },
  { id: 'docNum', label: 'Sap', alignRight: false },
  { id: 'sociedad', label: 'Sociedad', alignRight: false },
  { id: 'solicitante', label: 'Solicitante', alignRight: false },
  { id: 'comments', label: 'Comentarios', alignRight: false },
  { id: 'status', label: 'Estado', alignRight: false },
  { id: 'createdAt', label: 'Fecha', alignRight: false },
];

export default function DocumentPage() {
  const { data } = useAuthStore();
  const { reqApprAdminAll } = useRequestApproval();
  const [ loading, setLoading ] = useState(true);
  const [ documents, setDocuments ] = useState([]);

  const fetchData = async () => {
    const { success, result } = await reqApprAdminAll(data?.user?.id);
    setLoading(false);
    setDocuments(result);
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
            <Stack alignItems="center" direction="row" spacing={2}>
                <Typography variant="h4" >
                    Documentos generados
                </Typography>
            </Stack>
        </Stack>
        <Card spacing={3} style={{marginTop: 10}}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Card>
                        {loading && (
                            <Skeleton variant="rounded" height={350} />
                        )}
                        { !loading && (
                            <TableCustomer tableHead={TABLE_HEAD} typeTable="request-documents" data={documents} />
                        )}
                    </Card>
                </Grid>
            </Grid>
        </Card>
    </Container>
  )
}
