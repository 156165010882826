/* eslint-disable no-unused-vars */
import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        status: 'not-authenticated',
        data: {}
    },
    reducers: {
        onLogin: ( state, { payload } ) => {
            state.status = 'authenticated';
            state.data = payload;
        },
        onLogout: ( state, { payload } ) => {
            state.status = 'not-authenticated';
            state.data = {};
        },
        onChecking: ( state ) => {
            state.status = 'checking';
            state.data   = {};
        }
    }
});

export const { onLogin, onLogout, onChecking } = authSlice.actions;