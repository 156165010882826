/* eslint-disable no-plusplus */
/* eslint-disable import/order */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'
import { NavLink as RouterLink } from 'react-router-dom';
import { SvgIcon, TableRow, TableCell, Tooltip, IconButton, CircularProgress } from '@mui/material';
import moment from 'moment';
import Download01 from '@untitled-ui/icons-react/build/esm/Download01';
import Edit05 from '@untitled-ui/icons-react/build/esm/Edit05';
import Label from '../../label';
import { usePurchases } from '../../../hooks';
import Modal from '../../modal';
import { SolpedDetail } from '../../details';

export default function BodyPurchaseRequest(props){
  const { filtered, page, rowsPerPage, emptyRows } = props;
  const { pdfDocument, PDFDocument, SolpedDocument } = usePurchases();
  const [ downloadPdf, setDownloadPdf ] = useState(false);
  const [ rows, setRows ] = useState(null);
  const [ openSP, setOpenSP ] = useState(false);
  const [ docEntry, setDocEntry ] = useState(null);

  useEffect(() => {
    if (pdfDocument) {
      const linkSource = `data:application/pdf;base64, ${pdfDocument}`;
      const downloadLink = document.createElement("a");
      const fileName = `Documento-${rows}.pdf`;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      setDownloadPdf(false);
    }
  }, [pdfDocument]);
  
  const handleDownloadPdf = async (doc) => {
    setDownloadPdf(true);
    setRows(doc.DocNum);
    await PDFDocument(doc.DocEntry);
  };

  const handleSolped = async (doc) => {
    setOpenSP(true);
    setDocEntry(doc.DocEntry);
  }

  return (
    <>
      {
        filtered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
          const { DocEntry, DocNum, DocDate, Comments, AuthorizationStatus, DocumentStatus, Requester } = row;
          return (
            <TableRow hover key={index} tabIndex={-1}>
              <TableCell align="left">
                {DocNum}
              </TableCell>
              <TableCell align="left">
                {moment(DocDate).format("DD/MM/YYYY")}
              </TableCell>
              <TableCell align="left">
                {Comments}
              </TableCell>
              <TableCell align="left">
                {
                  DocumentStatus === 'bost_Open' ? 
                  <Label color={'success'}>
                    Abierto
                  </Label>
                  :
                  <Label color={'error'}>
                    Cerrado
                  </Label>
                }
              </TableCell>
              <TableCell align="left">
                {
                  AuthorizationStatus === 'dasRejected' ? 
                  <Label color={'error'}>
                    Rechazado
                  </Label>
                  : 
                  AuthorizationStatus === 'dasApproved' ? 
                  <Label color={'success'}>
                    Aprobado
                  </Label>:null
                }
              </TableCell>
              <TableCell align="right">
                <Tooltip title={'PDF'} placement="top-start">
                  <IconButton onClick={() => handleDownloadPdf(row)}>
                    {
                      downloadPdf && rows === row.DocNum ? 
                        <CircularProgress size={20} color="inherit" style={{ marginLeft: '10px' }}/>
                      :
                        <SvgIcon>
                          <Download01 />
                        </SvgIcon>
                    }
                  </IconButton>
                </Tooltip>
                {/* {
                  DocumentStatus === 'bost_Open' && AuthorizationStatus === 'dasRejected' ? 
                  <Tooltip title={'Editar'} placement="top-start">
                    <IconButton onClick={() => handleSolped(row)} component={RouterLink} to={`detalle-solicitud/${DocEntry}/${DocNum}`}>
                      <SvgIcon>
                        <Edit05 />
                      </SvgIcon>
                    </IconButton>
                  </Tooltip>:null
                } */}
              </TableCell>
            </TableRow>
          );
        })
      }

      {
        emptyRows > 0 && (
          <TableRow style={{ height: 53 * emptyRows }}>
            <TableCell colSpan={7} />
          </TableRow>
        )
      }

    <Modal title="Detalle solitud de compra" maxWidth={'xs'} open={openSP} onClose={() => setOpenSP(false)}>
      <SolpedDetail docEntry={docEntry} />
    </Modal>
  </>
  )
}

