/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import ArrowRight from '@untitled-ui/icons-react/build/esm/ArrowRight';
import { NavLink as RouterLink } from 'react-router-dom';
import moment from 'moment';
import { Stack, Popover, TableRow, Tooltip, SvgIcon, IconButton, MenuItem, TableCell, Typography } from '@mui/material';
import { UserForm, BusinessUserForm, CenterCostUserForm, ApproverUserForm } from '../../forms';
import Iconify from '../../iconify';
import Label from '../../label';
import { useUsers } from '../../../hooks';

export default function BodyApprovalPurchase({ filtered, page, rowsPerPage, emptyRows, refresh, openBackdrop }){
  const { ChangeStatus, ResetPassword } = useUsers();
  const [data, setData] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalType, setOpenModalType] = useState('user');

  const handleOpenMenu = (event, usr) => {
    setData(usr);
    setOpenMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenu(null);
  };

  const handleOpenModal = (type) => {
    setOpenModal(true);
    setOpenModalType(type);
  }

  const handleCloseModal = () => {
    setData(null);
    setOpenModal(false);
    setOpenMenu(false);
  };

  const handleResetPassword = async () => {
    setOpenMenu(false);
    openBackdrop(true);
    try {
      await ResetPassword(data?.id);
      openBackdrop(false);
    } catch (err) {
      console.log(err);
    }
  }

  const changeStatus = async (row) => {
    await ChangeStatus(row.id);
    refresh();
}

  const onSubmit = () => {
    setData(null);
    setOpenModal(false);
    setOpenMenu(false);
    refresh();
  }

  return (
    <>
      {
        filtered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
          const { id, uuid, sociedad, solicitante, comments, status, createdAt  } = row;
          return (
            <TableRow hover key={id} tabIndex={-1}>
                <TableCell component="th" scope="row">
                    <Typography variant="subtitle2" noWrap>
                        {id}
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    {sociedad}
                </TableCell>
                <TableCell align="left">
                    {solicitante}
                </TableCell>
                <TableCell align="left">
                    {
                        status === 'autorizado' ? 
                        <Label color={'success'}>
                            Aprobado
                        </Label>
                        : status === 'pendiente' ?
                        <Label color={'warning'}>
                            Pendiente
                        </Label>
                        : status === 'reproceso' ?
                        <Label color={'warning'}>
                            Reprocesar
                        </Label>
                        :
                        <Label color={'error'}>
                            Rechazado
                        </Label>
                    }
                </TableCell>
                <TableCell align="left">
                  {moment(createdAt).format("DD/MM/YYYY")}
                </TableCell>
                <TableCell align="right">
                    <Tooltip title={'Detalle solicitud'} arrow>
                        <IconButton component={RouterLink} to={`detalle/${id}/${uuid}`}>
                            <SvgIcon>
                                <ArrowRight />
                            </SvgIcon>
                        </IconButton>
                    </Tooltip>
                    {/* <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, row)}>
                    <Iconify icon={'eva:more-vertical-fill'} />
                    </IconButton> */}
                </TableCell>
            </TableRow>
          );
        })
      }

      {
        emptyRows > 0 && (
          <TableRow style={{ height: 53 * emptyRows }}>
            <TableCell colSpan={7} />
          </TableRow>
        )
      }

      <Popover
        open={Boolean(openMenu)} 
        anchorEl={openMenu} 
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75
            }
          }
        }}
      >
        <MenuItem onClick={() => handleOpenModal('user')}>
          <Iconify icon={'eva:alert-circle-outline'} sx={{ mr: 2 }} />
          Detalle
        </MenuItem>
      </Popover>
    </>
  )
}
