/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/prop-types */
import React, {useEffect} from 'react'
import { styled, Paper, Typography, Stack, Skeleton, Box} from '@mui/material';
import { useLogs } from '../../../hooks';
import { Code } from '../../code';
import Label from '../../label';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function DetailsLog(props){
    const { data } = props;
    const { loading, FindLog, log } = useLogs();

    const searchLog = async () => {
        await FindLog(data?.id);
    }

    useEffect(() => {
        searchLog();
    }, []);

  return (
    <>
        {
            loading ? (
                <>
                    <Box sx={{ pt: 0.5 }}>
                        <Skeleton />
                        <Stack direction="row" justifyContent="center" alignItems="center" spacing={1} >
                            <Skeleton width="33%" />
                        </Stack>
                        <Stack direction="row" justifyContent="center" alignItems="center" spacing={1} >
                            <Skeleton width="33%" />
                            <Skeleton width="33%" />
                        </Stack>
                        <Skeleton />
                        <Skeleton />
                    </Box>
                </>
            ):(
                <>
                    <Typography variant="h5" gutterBottom>
                        {log?.description}
                    </Typography>
                    <Stack direction="row" justifyContent="center" alignItems="center" spacing={1} >
                        <Item>
                            <Typography variant="caption" display="block" gutterBottom>
                                <Label color={'info'}>{log?.api}</Label>
                            </Typography>
                        </Item>
                    </Stack>
                    <Stack direction="row" justifyContent="center" alignItems="center" spacing={1} >
                        <Item>
                            <Typography variant="caption" display="block" gutterBottom>
                                <Label color={'info'}>Método: {log?.method}</Label>
                            </Typography>
                        </Item>
                        <Item>
                            <Typography variant="caption" display="block" gutterBottom>
                                {
                                    log?.code === '200' ? (
                                        <Label color={'success'}>
                                            Código: {log?.code}
                                        </Label>
                                    ): log?.code === '400' ? (
                                        <Label color={'warning'}>
                                            Código: {log?.code}
                                        </Label>
                                    ): log?.code === '500' ? (
                                        <Label color={'error'}>
                                            Código: {log?.code}
                                        </Label>
                                    ):null
                                }
                            </Typography>
                        </Item>
                    </Stack>
                    {
                        log?.code !== '200' ? (
                            <>
                                <Typography variant="subtitle2" color="text.secondary">
                                    Respuesta
                                </Typography>
                                <Typography variant="body2" color="text.secondary" style={{ textAlign: 'center' }}>
                                    <Label color={'error'}>{log?.response}</Label>
                                </Typography>
                                <Typography variant="subtitle2" color="text.secondary">
                                    Solicitud
                                </Typography>
                                <Code json={log?.request} />
                            </>
                        ):null
                    }
                </>
            )
        }
    </>
  )
}
