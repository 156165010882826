// @mui
import { styled } from '@mui/material/styles';
import { Container, CircularProgress } from '@mui/material';

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
  alignItems: 'center'
}));

export default function LoadingPage() {
  return (
    <>
      <Container>
        <StyledContent>
          <CircularProgress size={80} style={{ color: '#2594a5', marginLeft: '10px' }}/>
        </StyledContent>
      </Container>
    </>
  );
}
