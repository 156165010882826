/* eslint-disable no-unneeded-ternary */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'
import {
    styled,
    TextField,
    Button,
    Paper,
    Grid,
    CircularProgress,
    IconButton
} from '@mui/material';
import { useSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import * as yup from 'yup';
import { BusinessSchema, RulesSchema } from '../../../utils/validacionSchema';
import { useBusiness, useRules } from '../../../hooks';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function RulesForm(props){
    const { rules, reload } = props;
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { onRules, upRules } = useRules();
    const [ formData, setFormData ] = useState({ 
        description: rules?.description || '', 
        ends_in: rules?.ends_in || '',
        go_with: rules?.go_with || ''
    });
    const [ load, setLoad ] = useState(false);
    const [ errors, setErrors ] = useState([]);

    const inputChange = (event) => {
        const { name, value } = event.target;
        let processedValue = value;

        if (name === 'description') {
            processedValue = value;
        } else if (name === 'ends_in') {
            processedValue = value.toUpperCase();
        } else if (name === 'go_with') {
            processedValue = value.toUpperCase();
        };

        setFormData((prevState) => ({
            ...prevState,
            [name]: processedValue,
        }));
        RulesSchema.validateAt(name, { [name]: processedValue })
        .then(() => {
            setErrors({ ...errors, [name]: "" });
        })
        .catch((err) => {
            setErrors({ ...errors, [name]: err.errors[0] });
        });
    };

    const submitForm = async () => {
        try {
            setLoad(true);
            await RulesSchema.validate(formData, { abortEarly: false });
            console.log(formData);
            
            if (rules == null) {
                await onRules(formData);
            } else {
                await upRules(rules.id, formData);
            }
            reload();
            setLoad(false);
        } catch (err) {
            setLoad(false);
            enqueueSnackbar(`Formulario incompleto!`, {
                variant: 'error',
                autoHideDuration: 3000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
                action: (key) => (
                    <IconButton onClick={() => { closeSnackbar(key) }}>
                      <CloseIcon />
                    </IconButton>
                )
            });
            if (err instanceof yup.ValidationError) {
                setErrors(err.inner.reduce((acc, curr) => {
                    acc[curr.path] = curr.message;
                    return acc;
                }, {}));
            }
        }
    }

  return (
    <Grid container>
        <Grid item xs={12} sm={12} md={12}>
            <Item>
                <TextField 
                    autoFocus 
                    id="description"
                    name="description"
                    label="Descripción" 
                    type="text" 
                    size="small" 
                    variant="outlined" 
                    fullWidth 
                    onChange={inputChange}
                    value={formData.description}
                    error={Boolean(errors.description)}
                    helperText={errors.description}
                    autoComplete="off" />
            </Item>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
            <Item>
                <TextField 
                    id="ends_in" 
                    name="ends_in"
                    label="Termina en"
                    type="text"
                    size="small" 
                    variant="outlined" 
                    fullWidth
                    onChange={inputChange}
                    value={formData.ends_in}
                    error={Boolean(errors.ends_in)}
                    helperText={errors.ends_in}
                    autoComplete="off" />
            </Item>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
            <Item>
                <TextField 
                    id="go_with"
                    name="go_with"
                    label="Van con" 
                    type="text" 
                    size="small" 
                    variant="outlined" 
                    fullWidth 
                    onChange={inputChange}
                    value={formData.go_with}
                    error={Boolean(errors.go_with)}
                    helperText={errors.go_with}
                    autoComplete="off" />
            </Item>
        </Grid>
        {formData.ends_in && formData.go_with && (
            <Grid item xs={12} sm={12} md={12}>
                <Item>
                    Combinación [ 0000{formData.ends_in} = {formData.go_with}0000 ]
                </Item>
            </Grid>
        )}
        <Grid item xs={12} style={{display:'flex', justifyContent:'flex-start'}}>
            <Item>
                <Button variant="contained" onClick={submitForm} disabled={load}>
                    {rules == null ? 'Registrar':'Actualizar'}
                    {
                        load ? (
                            <CircularProgress size={20} color="inherit" style={{ marginLeft: '10px' }}/>
                        ):(
                            ''
                        )
                    }
                </Button>                   
            </Item>
        </Grid>
    </Grid>
  )
}
