import { useState } from 'react';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from 'notistack';
import { purchaseRequestAll, purchaseRequestApprovalFindAll, purchaseRequestApprovalUpdate } from '../../service';

export const useRequestApproval = () =>{
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const reqApprAdminAll = async () => {
    try {
      const { data:{ success, message, result } } = await purchaseRequestAll(`?business=${localStorage.getItem('business')}`);
      return { success, result };
    } catch (error) {
      return error;
    }
  }

  const reqApprFindAll = async () => {
    try {
        const { data:{ success, message, result } } = await purchaseRequestApprovalFindAll(`?business=${localStorage.getItem('business')}`);
        return { success, result };
    } catch (error) {
        return error;
    }
  }

  const reqApprUpdate = async (id, body) => {
    try {
      const { data:{ success, message, result } } = await purchaseRequestApprovalUpdate(id, `?business=${localStorage.getItem('business')}`, body);
      return { success, result };
    } catch (error) {
      enqueueSnackbar(`La solicitud no se realizo exitosamente`, {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
        },
        action: (key) => (
            <IconButton onClick={() => { closeSnackbar(key) }}>
                <CloseIcon />
            </IconButton>
        )
      });
      throw error.response.data;
    }
  }

  return {
    reqApprAdminAll,
    reqApprFindAll,
    reqApprUpdate
  }
}
