import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { GETDraftDetail, GETDrafts, GETDraftUsrs } from '../../service';

export const useApprover = () =>{
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [ loading, setLoading ] = useState(false);
  const [ skeleting, setSkeleting ] = useState(false);

  const requestsApprovals = async () => {
    try {
      const { data:{ success, message, result } } = await GETDrafts(`?business=${localStorage.getItem('business')}`);
      return { success, result };
    } catch (error) {
      return error;
    }
  }

  const requestsApprovalUsrs = async () => {
    try {
      const { data:{ success, message, result } } = await GETDraftUsrs(`?business=${localStorage.getItem('business')}`);
      return { success, result };
    } catch (error) {
      return error;
    }
  }

  const detailApproval = async (id, uuid) => {
    try {
        const { data:{ success, message, result } } = await GETDraftDetail(id, uuid);
        return { success, result };
    } catch (error) {
        return error;
    }
  }


  return {
    requestsApprovals,
    requestsApprovalUsrs,
    detailApproval
    // userApprovers,
    // loading,
    // skeleting,
    // inApprovers,
    // FindUserApprovers,
    // RelationUserApprover,
    // DeleteApproverUser
  }
}
